<template>
  <div>
      <p class="text-center">The INET is currently in development. Please contact support if you wish to discuss requirements whilst we are building.</p>
  </div>
</template>

<script>
export default {
  created() {
    this.getView()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/inet')
        .then(() => {
        })
        .catch(() => {
        })
    },
  }
}
</script>

<style>

</style>